@import 'typography/mixins';
@import 'bootstrap/scss/vendor/rfs';
@import 'bootstrap/scss/mixins';

app-blue-cards-slice {
  & .text-white {
    min-height: 300px;
    @include font-weight-300();

    & h3 {
      @include font-size(1.3rem);
    }

    & p {
      @include font-size(1rem);
      line-height: 1.4rem;

      &:last-child {
        margin: 0;
      }
    }
  }
}
