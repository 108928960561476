@import './styles/aos';
@import './styles/material';
@import './styles/bootstrap';
@import './styles/typography';
@import './styles/components';
@import './styles/utils';
@import './styles/variables';
@import './styles/photoswipe';

:root {
  --range-color-yellow: #{$color-yellow};
  --range-color-blue: #{$color-blue};
  --range-color-blue-light: #{$color-blue-light};
  --range-color-grey-dark: #{$color-grey-dark};
  --range-color-grey-light: #{$color-grey-light};
  --range-color-grey-middle: #{$color-grey-middle};
  --range-color-white: #{$color-white};
}
