app-rating-and-contact-slice {
  display: block;
  padding: 5rem 0;

  & p {
    font-size: 16px;
    line-height: 21px;
    margin: 0;
  }

  & app-rating {
    display: flex;
    flex-direction: column-reverse;
  }
}
