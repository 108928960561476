@import 'bootstrap/scss/functions';
@import 'bootstrap/scss/variables';
@import 'bootstrap/scss/mixins';

.price-area {
  padding: 1rem;
  background-color: var(--range-color-grey-middle);

  &.only-price {
    @include media-breakpoint-up(md) {
      width: 50%;
      align-self: end;
    }
  }

  & > div {
    display: flex;
    flex-direction: column;

    &:last-child {
      @include media-breakpoint-up(md) {
        text-align: right;
      }
    }

    & strong {
      font-size: 1.5rem;
      font-weight: 300;
    }

    & span {
      &:not(:last-child) {
        color: var(--range-color-blue);
        font-size: 1.8rem;
        line-height: 2rem;
        font-weight: 600;
      }
      &:last-child {
        font-weight: 300;
        font-size: 80%;
      }
    }
  }
}
