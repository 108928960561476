@import 'typography/mixins';
@import 'variables';

@mixin last-child-margin($margin: 0.2rem) {
  &:not([itemprop]) {
    margin-bottom: $margin;
  }
}

@mixin split-list($margin) {
  & > div {
    display: flex;
    justify-content: space-between;

    & > span {
      &:nth-of-type(1) {
        @include font-weight-600();
      }
      &:nth-of-type(2) {
        @include font-weight-300();
        text-align: end;
      }
    }

    &:not(:last-child) {
      @include last-child-margin($margin: $margin);
    }
  }

  &.last-child-margin {
    & > div {
      &:last-child {
        @include last-child-margin($margin: $margin);
      }
    }
  }
}

@mixin apply-split-list() {
  &:not(.wide) {
    @include split-list(0.2rem);
  }
  &.wide {
    @include split-list(0.7rem);
  }
}

.split-list {
  @include apply-split-list();
}

app-vehicle-search-result-card,
app-vehicle-detail-stats,
app-vehicle-card {
  & .vehicle-attributes {
    & > div {
      @include apply-split-list();
    }
  }

  & .price {
    & span {
      &:nth-child(1) {
        @include font-weight-600();
        font-size: 1.5rem;
        line-height: 1.4rem;
      }
      &:nth-child(2),
      &:nth-child(3) {
        @include font-weight-300();
      }
      &:nth-child(3) {
        font-size: 80%;
      }
    }
  }
}

app-vehicle-detail-stats {
  display: flex;
  flex-direction: column;
  height: calc(100% - 24px - 0.5rem);
}

.vehicle-search-gallery-backdrop {
  background: rgba(255, 255, 255, 0.95);
}

.favorite-icon {
  & path {
    transition: all 200ms ease;
    fill: $color-grey-dark;
    &.red {
      fill: red;
    }
  }
}
