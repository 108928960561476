.Farbe-Blau {
  color: var(--range-color-blue);
}

.Farbe-Gelb {
  color: var(--range-color-yellow);
}

.Farbe-Dunkelgrün {
  color: #2b5900;
}

.Farbe-Hellgrün {
  color: #4e9d00;
}

.Farbe-Rot {
  color: #d23100;
}
