.divider-vertical,
.divider-horizontal {
  background-color: var(--range-color-grey-dark);
}

.divider-vertical {
  height: 100%;
  width: 1px;
}

.divider-horizontal {
  width: 100%;
  height: 1px;
}
