@import 'variables';
@import 'typography/mixins';
@import 'utils/mixins';

$form-div-margin-bottom: 1rem;
$font-size: 14px;
$font-size-large: 16px;
$animation-duration: 400ms;

@mixin hidden() {
  overflow: hidden;
  max-height: 0 !important;
  margin: 0 !important;
  opacity: 0;
}

.range-form {
  @include range-border-radius-m();
  @include range-shadow();
  padding: 1.1rem 1.5rem 1.5rem;
  background-color: $color-grey-dark;
  color: $color-white;
  font-size: $font-size;
  position: relative;
  max-height: 100vh;
  transition: max-height 500ms ease;

  &.blue {
    background-color: $color-blue;
  }

  & .hidden {
    @include hidden();
  }

  & label {
    @include font-weight-500();
    font-size: 95%;
  }

  & textarea {
    min-height: 133px;
  }

  & button {
    &.hidden {
      display: none;
    }
    margin-top: 0.5rem;
    transition: all $animation-duration ease;
  }

  & > div {
    transition: all $animation-duration ease;

    &:not(:last-child):not(.result-message) {
      margin-bottom: $form-div-margin-bottom;
    }
  }

  & .form-control,
  & .form-select {
    color: $color-white;
    padding: 0.75rem;
    background-color: transparent;
    border: 2px solid $color-white;
    border-radius: 4px;
    @include font-weight-300();
    font-size: $font-size;

    &.ng-pristine,
    &::placeholder {
      color: transparentize($color-white, 0.5);
      transition: all 150ms ease;
    }

    & option {
      color: $color-grey-dark;
    }

    &:focus {
      box-shadow: none;
    }
  }

  & .form-select {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23fff' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='m2 5 6 6 6-6'/%3e%3c/svg%3e");
  }

  & .form-floating {
    & > label {
      @include font-weight-300();
      opacity: 0.75;
    }
  }

  & .post-submit-info {
    @include font-weight-300();
    display: block;
    margin-top: calc(-0.2rem + 19.5px);
  }

  & .title {
    display: block;
    margin-bottom: 1rem;
    font-size: $font-size * 1.15;
  }

  & .progress-bar {
    $progress-bar-height: 8px;
    border-top-left-radius: $progress-bar-height;
    border-top-right-radius: $progress-bar-height;
    height: $progress-bar-height;
    width: 100%;
    overflow: hidden;
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0;
    transition: all 100ms ease;

    & > div {
      background-color: $color-white;
      border-radius: $progress-bar-height;
      height: calc($progress-bar-height / 3);
      width: 100%;
      position: absolute;
      left: -100%;
      bottom: 0;
      top: 0;
      transition: left 10s ease;
    }

    &.loading {
      & > div {
        left: -10%;
      }
    }
  }

  &.loading {
    & .progress-bar {
      opacity: 1;
    }
  }

  &.small-form {
    &.success,
    &.failure {
      & > div:not(.result-message) {
        @include hidden();
      }
      & > button {
        display: none;
      }
    }
  }

  &.light {
    background-color: transparent;
    color: $color-grey-dark;
    box-shadow: none;
    padding: 0;

    $placeholder-color: transparentize($color-grey-dark, 0.5);

    & .form-control,
    & .form-select {
      color: $color-grey-dark;
      border-color: $color-grey-dark;

      &::placeholder,
      & option {
        color: $placeholder-color;
      }

      &:focus {
        border-color: $color-blue;
      }
    }

    & .form-select {
      background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23333' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='m2 5 6 6 6-6'/%3e%3c/svg%3e");

      &.ng-invalid {
        color: $placeholder-color;
      }
    }
  }

  &.large {
    font-size: $font-size-large;

    & .form-control,
    & .form-select {
      font-size: $font-size-large;
    }

    & .title {
      font-size: $font-size-large * 1.15;
    }
  }

  &.fade-out,
  & .fade-out {
    max-height: 0 !important;
    overflow: hidden;
  }

  &.fade-in {
    max-height: 100vh;
  }
}

app-contact-form-slice {
  & ~ app-text-slice {
    display: block;
    margin-top: 150px;
  }
}
