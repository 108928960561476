@import '../variables';

@each $name, $color in $colors {
  .color-#{$name} {
    color: $color;
  }
  .bg-#{$name} {
    background-color: $color;
  }
}
