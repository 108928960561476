@import 'typography/mixins';
@import 'variables';

@mixin active-style($color: $color-blue) {
  cursor: pointer;

  &.router-link-active {
    color: $color;
  }

  &[href] {
    &:hover {
      color: $color;
    }
  }
}

app-navbar {
  & app-router-link-or-href a,
  & .nav-item-no-link {
    display: inline-flex;
    @include font-weight-300();
    @include no-decoration();
    transition: all 300ms cubic-bezier(0, 0.41, 0.24, 1);

    @include active-style();
  }

  & .desktop-nav {
    $nav-item-margin-left: 1rem;
    & app-router-link-or-href a,
    & .nav-item-no-link {
      & ~ .nav-item-no-link,
      & ~ app-router-link-or-href a {
        margin-left: $nav-item-margin-left;
      }
    }

    & app-favorite-vehicles-icon,
    & app-login-icon {
      margin-left: $nav-item-margin-left;
    }
  }

  & .mobile-nav {
    & app-router-link-or-href a,
    & .nav-item-no-link {
      font-size: 1.2rem;

      & ~ .nav-item-no-link,
      & ~ app-router-link-or-href a {
        margin-top: 0.5rem;
      }
    }

    & .child-items {
      & app-router-link-or-href a {
        font-size: 1rem;
        @include font-weight-400();
      }
    }
  }

  & ~ app-text-slice {
    display: block;
    margin-top: 75px;
  }

  & .sub-bar {
    &:not(.white) {
      & svg {
        & path {
          fill: $color-white;
        }
      }
      & app-router-link-or-href {
        & a {
          @include active-style($color-white);
        }
      }
    }
  }
}
