@import 'variables';
@import 'utils/mixins/border-radius';

app-three-column-content-slice {
  & .block-img {
    & img {
      width: 100%;
    }
  }

  & h4,
  & ul {
    color: #4d4d4d;
    background-color: #e9e9e9;
  }

  & h4 {
    margin: 0;
    padding: 0.8rem;

    @include range-border-radius-s();
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }
  & ul {
    padding-left: 1.8rem;
    padding-bottom: 0.8rem;
    padding-right: 0.8rem;

    @include range-border-radius-s();
    border-top-left-radius: 0;
    border-top-right-radius: 0;
  }
}
