@import '../typography/mixins';
@import '../variables';
@import './mixins/button';
@import 'bootstrap/scss/vendor/rfs';
@import 'bootstrap/scss/mixins';

.range-btn {
  @include font-weight-600();
  @include font-size(15px);

  display: inline-flex;
  align-items: center;
  align-self: flex-end;
  -webkit-appearance: unset;
  text-decoration: none;
  transition: all 200ms ease;

  @include padding(8px 18px);
  border-width: 0;
  border-radius: 16px;

  &.blue {
    @include range-btn-blue();
  }

  &.white {
    @include range-btn-white();
  }

  &.small {
    @include font-size(12px);
    @include padding(2px 7px);
  }
}

.inline-btn {
  display: inline-flex;
  background: transparent;
  border: none;
  padding: 0;
}
