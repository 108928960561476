@mixin font-weight-300() {
  font-weight: 300;
}
@mixin font-weight-400() {
  font-weight: 400;
}
@mixin font-weight-500() {
  font-weight: 500;
}
@mixin font-weight-600() {
  font-weight: 600;
}

@mixin color-inherit() {
  color: inherit;
  &:hover {
    color: inherit;
  }
}

@mixin no-decoration() {
  text-decoration: none;
  &:hover {
    text-decoration: none;
  }
}

@mixin underline-only-hover() {
  text-decoration: none;
  &:hover {
    text-decoration: underline;
  }
}

@mixin font-roboto() {
  font-family: Roboto, sans-serif;
}

@mixin font-playfair() {
  font-family: 'Playfair Display', serif;
}
