app-energy-efficiency {
  display: block;
  width: 80px;
}

.pseudo-energy-efficiency-indicator,
app-energy-efficiency,
app-energy-efficiency-indicator {
  & .scale {
    user-select: none;
    text-align: right;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    color: #fff;

    & > div {
      $line-height: 22px;
      line-height: $line-height;
      padding: 0 3px;
      width: calc(48px + 3 * var(--index));
      position: relative;

      &:not(:last-child) {
        margin-bottom: 2px;
      }

      &::before {
        content: '\a';
        position: absolute;
        border-style: solid;
        border-width: 12px 5px 10px;
        left: -10px;
      }

      @mixin make-color-style($color) {
        background-color: $color;
        &::before {
          border-color: transparent $color transparent transparent;
        }
      }

      &.a-plus-plus-plus,
      &.a-plus-plus {
        @include make-color-style(#008d48);
      }
      &.a-plus {
        @include make-color-style(#46a34c);
      }
      &.a,
      &.b {
        @include make-color-style(#b3c94d);
      }
      &.c {
        @include make-color-style(#ffeb4b);
      }
      &.d {
        @include make-color-style(#e25833);
      }
      &.e,
      &.f,
      &.g {
        @include make-color-style(#d4162d);
      }

      &.highlight {
        &::after {
          $width: 4px;
          content: '';
          position: absolute;
          width: $width;
          right: -$width;
          background-color: #000;
          height: $line-height;
        }
      }
    }
  }
}
