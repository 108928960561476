@import 'bootstrap/scss/functions';
@import 'bootstrap/scss/variables';
@import 'bootstrap/scss/mixins';

.brand-logos {
  & img {
    &:not(:last-child) {
      margin-bottom: 1rem;
      @include media-breakpoint-up(md) {
        margin-bottom: 0;
        margin-right: 1rem;
      }
    }
  }

  &.brand-logos-small {
    & img {
      max-height: 1.7rem;
    }
  }
}
