@import '../variables';
@import 'bootstrap/scss/functions';
@import 'bootstrap/scss/variables';
@import 'bootstrap/scss/mixins';

app-text-slice {
  h1 {
    color: $color-blue;
  }

  & .pre-footer {
    &:first-child {
      padding-top: 8rem;
    }
  }

  & p {
    &:last-child {
      margin-bottom: 0;
    }
  }

  & .small {
    & p {
      @include font-size(0.9rem);
    }
  }

  & ~ app-campaigns-slider-slice {
    display: block;
    margin-top: 4rem;
  }

  & ~ app-blue-cards-slice {
    display: block;
    margin-top: 4rem;
  }

  & ~ app-testimonial-slice {
    display: block;
    margin-top: -1rem;

    @include media-breakpoint-up(lg) {
      margin-top: 5rem;
    }
  }

  & ~ app-two-columns-slice,
  & ~ app-three-column-content-slice {
    display: block;
    margin-top: 5rem;
  }

  & ~ app-icon-text-list {
    display: block;
    margin-top: 2rem;
  }
}
