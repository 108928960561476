@import 'typography/mixins';
@import 'utils/mixins/border-radius';
@import 'utils/mixins/shadow';
@import 'bootstrap/scss/functions';
@import 'bootstrap/scss/variables';
@import 'bootstrap/scss/mixins';

app-testimonial-slice {
  & .testimonial {
    & p {
      @include font-weight-300();
    }
  }

  & .video-wrapper {
    & .wistia_embed {
      & > div {
        @include range-shadow();
        @include range-border-radius-s();
      }
    }
  }

  & ~ app-testimonial-slice {
    display: block;
    margin-top: 130px;
  }
}
