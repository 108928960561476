@import 'bootstrap/scss/vendor/rfs';
@import 'bootstrap/scss/mixins';

app-campaigns-slider-slice {
  & .card {
    & .content {
      & h3 {
        @include font-size(1rem);

        @media (min-width: 600px) {
          @include font-size(1.1rem);
        }

        @media (min-width: 770px) {
          @include font-size(1.3rem);
        }

        @media (min-width: 950px) {
          @include font-size(1.5rem);
        }

        @media (min-width: 1100px) {
          @include font-size(1.9rem);
        }
      }

      & p {
        @include font-size(0.9rem);
        line-height: 22px;

        @media (min-width: 600px) {
          @include font-size(1rem);
        }

        @media (min-width: 700px) {
          @include font-size(1.1rem);
          line-height: 25px;
        }

        @media (min-width: 1000px) {
          line-height: 30px;
        }
      }
    }
  }
}
