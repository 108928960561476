@import '../../variables';

$disabled-color: #727272;

@mixin range-btn-blue() {
  background-color: $color-blue;
  color: $color-white;

  &:not(:disabled) {
    &:hover {
      background-color: $color-blue-middle;
    }
  }

  &:disabled {
    background-color: #e0e0e0;
    color: $disabled-color;
  }
}

@mixin range-btn-white() {
  background-color: $color-white;
  color: $color-grey-dark;

  &:not(:disabled) {
    &:hover {
      color: $color-blue;
      border-color: $color-blue;
    }
  }

  &:disabled {
    color: $disabled-color;
  }

  &.outline {
    border: 1px solid $color-grey-dark;
  }
}
