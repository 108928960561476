$color-yellow: #ffc107;
$color-blue: #35589a;
$color-blue-middle: #3e65af;
$color-blue-light: #f4faff;
$color-grey-dark: #333333;
$color-grey-light: #fcfcfc;
$color-grey-middle: #f5f5f5;
$color-white: #fff;

$color-paginator-text: #565656;

$colors: (
  'yellow': $color-yellow,
  'blue': $color-blue,
  'blue-light': $color-blue-light,
  'grey-dark': $color-grey-dark,
  'grey-light': $color-grey-light,
  'grey-middle': $color-grey-middle,
  'white': $color-white
);
