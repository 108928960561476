@import './fonts';
@import './mixins';
@import '../variables';
@import 'bootstrap/scss/vendor/rfs';
@import 'bootstrap/scss/mixins';

body {
  @include font-weight-400();
  @include font-roboto();
  color: $color-grey-dark;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  @include font-weight-600();
}

h1 {
  @include font-size(3.5rem);
  @include margin-bottom(1.1rem);
}

h2 {
  @include font-size(2rem);
  @include margin-bottom(1.2rem);
}

h3 {
  @include font-size(1.4rem);
  @include margin-bottom(1.2rem);
}

p {
  @include font-size(1.15rem);
  line-height: 28px;

  &.pre-footer {
    @include font-size(0.9rem);
    line-height: 24px;
  }
}

.pre-heading {
  @include font-size(0.8rem);
  @include font-weight-600();
  display: inline-block;
  margin-bottom: 4px;
}

.font-weight-300 {
  @include font-weight-300();
}
.font-weight-600 {
  @include font-weight-600();
}

a {
  @include color-inherit();

  &.no-decoration {
    text-decoration: none !important;
  }

  &.underline-only-hover {
    @include underline-only-hover();
  }
}

.no-wrap {
  white-space: nowrap;
}
