@import 'typography/mixins';

app-full-screen-image-slice {
  p {
    @include font-weight-300();
  }

  & ~ app-contact-form-slice {
    display: block;
    margin-top: -126px;
  }
}
