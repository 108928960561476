@import 'utils/mixins/border-radius';

app-person-card-video {
  & .video-wrapper {
    & .wistia_embed {
      & > div {
        @include range-border-radius-s();
      }
    }
  }
}
