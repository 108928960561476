@import 'bootstrap/scss/vendor/rfs';
@import 'bootstrap/scss/mixins';
@import 'typography/mixins';

app-footer {
  $paragraph-to-heading-distance: 1.5rem;
  $font-size: 0.9rem;

  & p {
    @include font-size($font-size);
    @include font-weight-300();
    line-height: 1.4rem;
    margin-bottom: 0;

    &.split {
      width: min(15rem, 90%);
    }

    & ~ p {
      margin-top: 0.5rem;

      &.split {
        margin-top: 0;
      }
    }
    & ~ h3,
    & ~ h4 {
      margin-top: $paragraph-to-heading-distance;
    }
  }

  & .mb-equal {
    margin-bottom: $paragraph-to-heading-distance;
  }

  & h3,
  & h4 {
    @include font-size($font-size);
    @include font-weight-500();
  }

  & h3 {
    margin-bottom: 1rem;
  }
  & h4 {
    margin-bottom: 0;
  }

  & a {
    @include underline-only-hover();
  }
}
