app-horizontal-line-slice {
  display: block;
  overflow: hidden;
  user-select: none;

  & ~ app-footer {
    & footer {
      margin-top: 5rem;
    }
  }
}
